
:root {
    --background-gradient-1: black;
    --background-gradient-2: rgb(59, 196, 150);
}

.projectCard {

    border-color: #ffffff;
    /* background-color: rgb(196, 120, 59); */
    background-color: black;
    /* background: linear-gradient(var(--background-gradient-1), var(--background-gradient-2)); */
    /* background-color: rgb(196, 120, 59); */
    /* background-color: rgb(189, 98, 24); */
    /* background-color: rgb(88, 0, 204); */
    /* background-color: rgb(96, 186, 202); */
    /* background-color: rgba(171, 238, 250, 0.699); */
    /* z-index: 500; */

    width: 100%;
    /* height: 100%; */
    height: auto;

    position: relative;

    display: flex;
    flex-direction: row;

    padding: 90px 0px;

    cursor: pointer;

    /* border-bottom: 2px solid rgb(75, 75, 75); */

}

.projectCardLeft {
   /* background: linear-gradient(var(--background-gradient-2), var(--background-gradient-1)) !important; */
}

.projectCard .leadImgContainer {

    flex: 1;
    overflow: hidden;
    /* height: 500px; */

}

.projectCard .leadImgContainer > img {
    width: 100%;
    height: 100%;
    max-height: 500px;;
    object-fit: cover;

}


.projectCard .textContainer {
    padding: 20px 50px;
    padding-top: 100px;
    flex: 1;
}

.projectCard .textContainer p {
    letter-spacing: 0.7px;
    line-height: 30px;
}




.projectCardLeft .leadImgContainer {
    /* Flot image right! */
    order: 2;
}


.productCardSpecs {
    opacity: 1;

}

.productCardSpecs h3 {
    opacity: 0.6;
}

.stackList {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.stackList > * {
    /* flex: 1; */
    background-color: rgb(73, 73, 73);
    padding: 10px;
    margin: 8px 8px;
    border-radius: 8px;
    color: white;
    opacity: 0.8;
    font-size: 15px;
}


@media only screen and (max-width: 800px) {
    .projectCard {
        flex-direction: column;
        height: auto;
        max-height: auto;
    }

    .projectCardLeft .leadImgContainer {
        /* Flot image right! */
        order: 0 !important;
    }

    .projectCard .textContainer {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 26px;
    }
}