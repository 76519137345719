


.meCard {

    border-color: rgba(255, 255, 255, 1);
    background-color: rgb(196, 120, 59);
    /* background-color: rgb(196, 120, 59); */
    /* background-color: rgb(189, 98, 24); */
    /* background-color: rgb(88, 0, 204); */
    /* background-color: rgb(96, 186, 202); */
    /* background-color: rgba(171, 238, 250, 0.699); */
    /* z-index: 500; */

    width: 100%;
    /* height: 100%; */
    height: auto;

    position: relative;

    display: flex;
    flex-direction: row;

}

.leadImgContainer {

    flex: 1;
    /* overflow: hidden; */

}

.leadImgContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}


.textContainer {
    padding: 20px 50px;
    padding-top: 100px;
    flex: 1;
}

.textContainer p {
    letter-spacing: 0.7px;
    line-height: 30px;
}



.promos {
    padding-top: 20px;
}

.promoCard {
    height: 100px;
    /* background-image: url($/resources/Subjects/Software1.jpg); */
    text-align: center;
    border-radius: 20px;

    cursor: pointer;
    opacity: 0.6;
    transition: ease-in-out 0.1s all;
}

.promoCard:hover {
    opacity: 1;
}



@media only screen and (max-width: 800px) {
    .meCard {
        flex-direction: column;
        height: auto;
    }
}