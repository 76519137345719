

.pageStorySection {

    border-top: 2px solid rgba(50, 50, 50, 1);
    padding-top: 20px;

}

.imageCarousel {
    padding-top: 20px;
}

.pageStoryDescription {

    padding: 40px 0px;
    opacity: 0.8;

}

.pageStoryDescription li {
    text-indent: 16px;
    padding: 10px 0px;
}

.pageStoryDescription li b {
    font-size: 18px;
    padding-right: 10px;
}



.pageStoryIframe {
    width: 100%;
    height: 800px;
}



@media only screen and (max-width: 800px) {
    
    .pageStoryDescription li {
        /* all: unset; */
        list-style-type: none;
        padding-left: 0px;
        text-indent: 0px;

        padding: 20px 0px;

        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    .pageStoryDescription li:last-child {
        border-bottom: none;
    }


    .pageStoryDescription li b:after {

    }
    .pageStoryDescription li b {
        padding-bottom: 6px;
        display: block;
    }

    
}