

.headerImgWrapper {


    width: 100%;
    height: 100%;

    position: relative;

    overflow: hidden;


}


.headerImgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headerImgFade {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    position: absolute;

    background: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));

}


.headerImgTxtWrapper {

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateX(-50%);
    transform: trnaslateY(-50%);

    text-align: center;


}

.headerImgTxt > h1 {

}

.headerImgTxt > p {
    opacity: 0.7;
}

.headerImgTxt > a {
    all: unset;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.2s ease-in-out all;
}

.headerImgTxt > a:hover {
    opacity: 1;
}





@media only screen and (max-width: 800px) {

    .headerImgTxtWrapper {
        width: 100%
    }


}