


.homePageContainer {
    /* margin-bottom: 50px; */

    /* margin-top: 100px; */

    /* padding: 0px 30px; */

    height: calc(100vh);

}

.homeCardCenterer {

    /* width: 50%; */
    /* max-width: 900px; */
    height: 100%;

    /* width: 70%;
    height: 70%;
    margin-top: 15%; */

    position: relative;
    /* top: 10%; */
    /* left: 15%; */

}